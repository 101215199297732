@import "./mlpartsVariables";

@mixin mlpartsContainer() {
    padding: 0 $container-padding;
    max-width: $container-max-width;
    width: auto !important;
    margin: 0 auto;
    @media (min-width: $screen-sm) {
        padding: 0 $container-padding-screen-sm;
    }
    @media (min-width: $screen-lg) {
        padding: 0 $container-padding-screen-lg;
    }
}

@mixin mlpartsPaneBorderShadow() {
    border-radius: $border-radius-primary;
    box-shadow: $box-shadow-light, inset $box-shadow-border;
}

@mixin mlpartsPaneHover($selector-pane, $selector-pane-inner: null, $color-bg: $color-red-primary, $color-text: white, $transition: null) {
    #{$selector-pane} {
        text-decoration: none !important;

        @media (max-width: $screen-xs-max) {
            @media (hover) {
                &:hover, &:active {
                    &, * {
                        color: $color-bg;
                    }
                }
            }
        }

        @media (min-width: $screen-sm) {
            transition: background-color $transition-duration-default, color $transition-duration-default, box-shadow $transition-duration-default, $transition;
            border-radius: $border-radius-primary;
            box-shadow: 0 0 0 1px transparent, 0 4px 8px 0 transparent;
            @media (hover) {
                &:hover {
                    background-color: $color-bg;
                    box-shadow: 0 0 0 1px $color-bg, 0 2px 8px 0 transparentize($color-bg, .5);

                    &, * {
                        color: $color-text !important;
                    }
                }
                &:active {
                    background-color: $color-bg;
                    transition-duration: .2s;
                    box-shadow: 0 0 0 1px $color-bg, 0 0 2px 0 transparentize($color-bg, .5);

                    &, * {
                        color: $color-text !important;
                    }
                }
            }
        }
    }
}

@mixin mlpartsBackgroundImageContain {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

@mixin mlpartsArrowDown() {
    &:after {
        background-color: $color-grey-600;
        $dataUri: "data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path d='m13.1768742 12.0301299-8.00529633-8.03544615 2.83373838-2.82310588 10.81780955 10.85855203-10.75777583 10.7982922-2.83373837-2.8231059z'/></svg>";
        mask-image: url($dataUri);
        -webkit-mask-image: url($dataUri);
        mask-size: 10px;
        -webkit-mask-size: 10px;
        content: '';
        height: 10px;
        width: 10px;
        display: block;
        position: absolute;
        transform: translateX(-50%) rotate(90deg);
        left: 50%;
        bottom: 8px;
    }
}

@mixin mlpartsMenuItemArrowRight($top: 40px, $responsive: true) {
    &:after {
        background-color: currentColor;
        $dataUri: "data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path d='m13.1768742 12.0301299-8.00529633-8.03544615 2.83373838-2.82310588 10.81780955 10.85855203-10.75777583 10.7982922-2.83373837-2.8231059z'/></svg>";
        mask-image: url($dataUri);
        -webkit-mask-image: url($dataUri);
        mask-size: $svgicon-size-sm;
        -webkit-mask-size: $svgicon-size-sm;
        content: '';
        height: $svgicon-size-sm;
        width: $svgicon-size-sm;
        display: block;
        position: absolute;
        right: 0;
        top: $top;
        margin-top: -.5 * $svgicon-size-sm;
        opacity: $opacity-arrow-light;
        @if $responsive == true {
            @media (min-width: $screen-sm) {
                right: 16px;
            }
        }
    }
}

@mixin mlpartsFontSizeResponsive($size, $size-min: round($size * 0.875)) {
    font-size: $size;
    @media (max-width: $screen-xs-max) {
        font-size: max(round($size * 0.875), $size-min);
    }
}

@mixin mlpartsFontSizeXlResponsive {
    font-size: 1.375rem;
    @media (max-width: $screen-xs-max) {
        font-size: 1.2rem;
    }
}

@function mlpartsGridBackgroundLines($verticalCellsCount: 2, $horizontalCellsCount: 2, $color: $color-borders) {
    $gradients: (none,);

    @if $verticalCellsCount > 1 {
        @for $i from 1 through ($verticalCellsCount - 1) {
            $position: "100% / #{$verticalCellsCount} * #{$i}";
            $gradients: append($gradients, linear-gradient(90deg, transparent calc(#{$position} - 1px), $color calc(#{$position} - 1px), $color calc(#{$position}), transparent calc(#{$position})));
        }
    }
    @if $horizontalCellsCount > 1 {
        @for $i from 1 through ($horizontalCellsCount - 1) {
            $position: "100% / #{$horizontalCellsCount} * #{$i}";
            $gradients: append($gradients, linear-gradient(180deg, transparent calc(#{$position} - 1px), $color calc(#{$position} - 1px), $color calc(#{$position}), transparent calc(#{$position})));
        }
    }

    @return $gradients;
}