@import "./mlpartsVariables";

.mlpartsDivider {
    display: block;
    height: 1px;
    width: 100%;
    background-color: $color-grey-100;

    &.--thick {
        height: $border-weight-thick;
        background-color: $color-grey-200;
    }

    &.--fullWidth {
        margin-left: - $container-padding;
        margin-right: - $container-padding;
        width: 100vw;
    }

    &.--onlyMobile {
        @media (min-width: $screen-sm) {
            display: none;
        }
    }

    .--dark & {
        background-color: $color-borders-semi-transparent-darken;
    }
}

.mlpartsCountryFlag {
    height: $svgicon-size-default;
    width: $svgicon-size-default;
    border-radius: $svgicon-size-default * 0.5;
    display: block;
    box-shadow: $box-shadow-dark;
}