@import "./mlpartsVariables";
@import "./mlpartsMixins";

%mlpartsHeaderResponsiveHeight {
    height: $layout-header-logo-bar-height + $layout-header-nav-bar-height;
    @media (min-width: $screen-lg) {
        height: 2 * $layout-header-nav-bar-height;
    }
}

.mlpartsHeader {
    position: relative;
    width: 100%;
    box-sizing: content-box;
    user-select: none;
    @extend %mlpartsHeaderResponsiveHeight;
}

.mlpartsHeader__sticky {
    position: fixed;
    left: 0;
    top: 0;
    z-index: $z-index-header-layout-sticky;
    width: 100%;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .25);
    transition-duration: $transition-duration-default;
    transition-property: top, transform;
    @extend %mlpartsHeaderResponsiveHeight;
    @media (max-width: $screen-md-max) {
        &.--scrollTop, &.--up, &.--active, body.--overlayActive & {
            //top: -$layout-header-logo-bar-height;
            transform: translateY(-$layout-header-logo-bar-height);
        }
    }
    @media (min-width: $screen-lg) {
        position: relative;
    }
}

.mlpartsHeader__background {
    @extend %mlpartsHeaderResponsiveHeight;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: $z-index-header-layout-background;
    width: 100%;
    //noinspection CssOverwrittenProperties
    background-image: url('../../mlparts.cz/header-image-2-1500.png');
    //noinspection CssOverwrittenProperties
    background-image: image-set(
            url('../../mlparts.cz/header-image-2-1500.webp') type("image/webp"),
            url('../../mlparts.cz/header-image-2-1500.png') type("image/png")
    );
    background-size: auto 300%;
    background-position: center center;
    background-color: $color-grey-500;

    &:after {
        content: '';
        left: 0;
        width: 100%;
        height: 1px;
        bottom: 0;
        position: absolute;
        background-color: $color-borders-semi-transparent-lighten;
    }
}

.mlpartsHeader__backgroundContainer {
    display: block;
    position: relative;
    height: 100%;
    @media (min-width: $screen-lg) {
        margin: 0 auto;
        max-width: $container-max-width;
        padding: 0 $container-padding-screen-lg;
        display: flex;
    }
}

.mlpartsHeader__dropdowns {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: $layout-header-dropdowns-height;
    @media (min-width: $screen-lg) {
        padding: 0 $container-padding-screen-lg;
    }
}

.mlpartsHeader__dropdownContainer {
    display: block;
    position: relative;
    height: 0;
    @media (min-width: $screen-lg) {
        margin: 0 auto;
        max-width: calc(#{$container-max-width} - 2 * #{$container-padding-screen-lg});
    }
}

.mlpartsHeader__logoBar {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    height: $layout-header-logo-bar-height;
    @media (min-width: $screen-lg) {
        flex-basis: 25%;
        height: auto;
        align-self: stretch;
        justify-content: flex-start;
    }

    &:before {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        bottom: 0;
        width: 9999px;
        background-size: auto 300%;
        background-position: right center;
        background-color: $color-red-primary;
        //noinspection CssOverwrittenProperties
        background-image: url('../../mlparts.cz/header-image-2-1500-red.png');
        //noinspection CssOverwrittenProperties
        background-image: image-set(
                url('../../mlparts.cz/header-image-2-1500-red.webp') type("image/webp"),
                url('../../mlparts.cz/header-image-2-1500-red.png') type("image/png")
        );
    }
}

.mlpartsHeader__logoImg {
    position: relative;
    z-index: 1;
    height: $layout-header-logo-bar-height - 32px;
    -webkit-user-drag: none;
    object-fit: contain;
    display: block;
    @media (min-width: $screen-lg) {
        height: 64px;
    }
}

.mlpartsHeader__navBar {
    flex-basis: 75%;
    position: relative;
    height: $layout-header-nav-bar-height;
    @media (min-width: $screen-lg) {
        height: 2 * $layout-header-nav-bar-height;
    }
}

.mlpartsHeader__navItem {
    width: calc(100% / 6);
    border: none;
    appearance: none;
    background: none;
    border-right: 1px solid $color-borders-semi-transparent-lighten;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $layout-header-nav-bar-height;
    font-size: .875rem;
    font-weight: $font-weight-semibold;
    color: white !important;
    text-transform: uppercase;
    position: absolute;
    text-decoration: none !important;
    top: 0;
    cursor: pointer;

    @media (hover) {
        &:hover, &:active {
            background: $color-borders-semi-transparent-lighten;
        }
    }

    &.--active, body:not(.--overlayActive) &.--current {
        background: $color-borders-semi-transparent-lighten;
    }

    @media (min-width: $screen-lg) {
        &.--doubleLine {
            height: $layout-header-nav-bar-height * 2;
        }
        &[data-dropdown-id] {
            @include mlpartsArrowDown();
        }
    }
}

.mlpartsHeader__navItemLine {
    height: $layout-header-nav-bar-height;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;

    &.--primary {
        @media (min-width: $screen-lg) {
            transform: translateY(-12px);
        }
    }

    &.--secondary {
        bottom: $layout-header-nav-bar-height;
        display: none;
        @media (min-width: $screen-lg) {
            display: flex;
        }
    }
}

.mlpartsHeader__navItemIcon {
    height: 22px;
    width: 100%;
    display: block;
    position: relative;
    @include mlpartsBackgroundImageContain;
    @media (min-width: $screen-lg) {
        height: 32px;
    }
}

.mlpartsHeader__navItemBadge {
    position: absolute;
    min-width: 1rem;
    min-height: 1rem;
    border-radius: 8px;
    background-color: $color-red-primary;
    color: white;
    top: -8px;
    left: calc(50% + 8px);
    padding: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $font-weight-semibold;
    font-size: .75rem;
    box-shadow: $box-shadow-brand;
    @media (min-width: $screen-lg) {
        left: calc(50% + 12px);
    }
}

.mlpartsHeader__navItem.--mainMenuOpener {
    left: 0;
    width: calc(100% / 6 * 2);
    justify-content: flex-start;
    padding-left: 1rem;
    @media (min-width: $screen-sm) {
        padding-left: 2rem;
    }
    @media (min-width: $screen-lg) {
        display: none;
    }

    > span {
        height: 16px;
        line-height: 16px;
        position: relative;
        padding-left: 32px;

        > span {
            display: block;
            position: absolute;
            left: 0;
            height: 2px;
            width: 18px;
            background-color: transparentize(white, .5);
            box-shadow: 0 1px 0 0 transparentize(black, .75);
            transition: transform $transition-duration-default;

            &:nth-child(1) {
                top: 1px;
            }

            &:nth-child(2) {
                top: 7px;
                transform: scaleX(calc(10 / 18));
                transform-origin: left center;
            }

            &:nth-child(3) {
                top: 13px;
                transform: scaleX(calc(14 / 18));
                transform-origin: left center;
            }
        }
    }

    @media (hover) {
        &:hover, &:active {
            > span > span {
                transform: scale(1);
            }
        }
    }
}

.mlpartsHeader__navItem.--categoryMenuOpener {
    left: 0;
    @media (max-width: $screen-md-max) {
        display: none;
    }
}

.mlpartsHeader__navItem.--infoMenuOpener {
    left: calc(100% / 6);
    @media (max-width: $screen-md-max) {
        display: none;
    }
}

.mlpartsHeader__navItem.--searchOpener {
    left: calc(100% / 6 * 2);

    .mlpartsHeader__navItemIcon {
        background-image: url('../../mlparts.cz/search.png');
    }

    @media (min-width: $screen-lg) {
        display: none;
    }
}

.mlpartsHeader__navItem.--contact {
    right: calc(100% / 6 * 3);
    @media (max-width: $screen-md-max) {
        display: none;
    }
}

.mlpartsHeader__navItem.--i18nMenuOpener {
    right: calc(100% / 6 * 2);
    @media (min-width: $screen-lg) {
        display: none;
    }
}

.mlpartsHeader__navItem__currency {
    display: block;
    text-align: center;
    line-height: 1;

    span {
        text-transform: lowercase;
        font-size: 11px;
        font-weight: $font-weight-regular;
        color: transparentize(white, .5);
        display: block;
    }
}

.mlpartsHeader__navItem__domain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.mlpartsHeader__navItem__domainName {
    margin-left: 8px;
}

.mlpartsHeader__navItem.--currencySwitcher {
    right: calc(100% / 6 * 2);
    justify-content: stretch;
    align-items: stretch;
    padding: 12px 16px;
    @media (max-width: $screen-md-max) {
        display: none;
    }
}

.mlpartsHeader__navItem.--i18nMenuOpenerDesktop {
    right: calc(100% / 6 * 2);
    justify-content: stretch;
    align-items: stretch;
    @media (max-width: $screen-md-max) {
        display: none;
    }
}

.mlpartsHeader__navItem.--myProfileMenuOpener {
    right: calc(100% / 6);

    .mlpartsHeader__navItemIcon {
        background-image: url('../../mlparts.cz/user@3x.png');
    }
}

.mlpartsHeader__navItem.--shoppingCart {
    right: 0;

    .mlpartsHeader__navItemIcon {
        background-image: url('../../mlparts.cz/header-cart.png');
    }
}

.mlpartsHeader__searchBar {
    @media (max-width: $screen-md-max) {
        top: $layout-header-logo-bar-height + $layout-header-nav-bar-height;
        left: 0;
        width: 100%;
        height: $layout-header-nav-bar-height;
        padding-top: 12px;
        position: absolute;
        transition-property: transform, height;
        transition-duration: $transition-duration-default;
        transform: translateY(-$layout-header-logo-bar-height);
        &.--active {
            //transition-delay: $transition-duration-default;
            transform: none;
        }
    }
    @media (min-width: $screen-lg) {
        position: relative;
        z-index: $z-index-header-layout-background;
        top: $layout-header-nav-bar-height;
    }
}

.mlpartsHeader__searchBar__container {
    @include mlpartsContainer();
    height: 0;
}

.mlpartsHeader__searchBar__cell {
    @media (min-width: $screen-lg) {
        width: 50%;
        position: relative;
        left: 25%;
    }
}

.mlpartsHeader__i18nDropdown {
    padding: 16px;
    .mlpartsDivider:last-child {
        display: none;
    }
}

.mlpartsHeader__i18nDropdown__item {
    position: relative;
    display: flex;
    align-items: center;
    height: $menu-item-height-lg;
    color: $color-text-dark;
    padding-right: 44px;
    @include mlpartsMenuItemArrowRight(50%);
}

@include mlpartsPaneHover('.mlpartsHeader__i18nDropdown__item');

.mlpartsHeader__i18nDropdown__item__countryFlag {
    padding: 0 16px;
}

.mlpartsHeader__i18nDropdown__item__domainInfo {
    display: flex;
    flex-direction: column;
}

.mlpartsHeader__i18nDropdown__item__domainName {
    color: $color-red-primary;
    font-weight: $font-weight-bold;
    font-size: $font-size-large;
    text-transform: uppercase;
}

.mlpartsHeader__i18nDropdown__item__domainLocale {
    font-size: $font-size-small;
    color: $color-text-dark;
    font-weight: $font-weight-regular;
}

.mlpartsHeader__myProfileDropdown {
    padding: 4px 16px;
    min-width: calc(min(320px, 100vw));
}

.mlpartsHeader__myProfileDropdown__title {
    color: $color-text-light;
    font-weight: $font-weight-bold;
    font-size: 1rem;
    height: 48px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.mlpartsHeader__myProfileDropdown__link {
    height: 48px;
    display: flex;
    align-items: center;
    appearance: none;
    border: none;
    background: none;
    width: 100%;
    font-weight: $font-weight-semibold;
    color: $color-grey-500;

    @media (hover) {
        &:hover, &:active {
            color: $color-red-primary;
        }
    }

    position: relative;
    //@include mlpartsMenuItemArrowRight(50%, false);
}

.mlpartsHeader__myProfileDropdown form {
    margin: 0 !important;
    display: grid;
    gap: 8px;

    input[type=submit] {
        margin: 0 !important;
        height: 40px;
    }
}

.mlpartsHeader__chatbox {
    position: fixed;
    z-index: 12;
    bottom: 0;
    right: 20px;
    display: flex;
    flex-direction: column;

    img {
        height: 24px;
        width: 24px;
        margin-right: 10px;
    }

    @media (max-width: 600px) {
        display: block;
    }
}

.mlpartsHeader__chatboxTop {
    @keyframes MoveUpDown {
        0%, 100% {
            transform: translateY(0);
        }
        33% {
            transform: translateY(5px);
        }
        66% {
            transform: translateY(5px);
        }
        100% {
            transform: translateY(0px);
        }
    }
    animation: MoveUpDown 2s linear infinite;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    @media (max-width: 600px) {
        display: none;
    }
}

.mlpartsHeader__chatboxInner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: auto;
    color: white;
    padding: 0 20px;
    background: $color-link;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, .05);
    transition: box-shadow .4s cubic-bezier(.175, .885, .32, 1.275);

    &:hover {
        box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, .2);
    }

    @media (max-width: 600px) {
        font-size: 0;
        img {
            margin: 0;
        }
    }
}