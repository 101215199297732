$font-family-primary: "Source Sans Pro", apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-system: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-size-root-px: 16px;
$font-size-large: 1.2rem;
$font-size-small: 0.875rem;
$font-size-smaller: 0.75rem;
$font-size-h1: 2.25rem;
$font-size-h2: 1.75rem;
$font-size-h3: 1.35rem;
$font-size-h4: 1.125rem;
$font-size-h5: 1rem;
$font-size-lg: 1.2rem;
$font-size-xl: 1.375rem;

$line-height: 1.5;
$line-height-smaller: 1.2;
$line-height-xs-heading: 1.1;

$letter-spacing-narrow: -.03em;

$screen-xl: 1500px;
$screen-lg: 1200px;
$screen-md: 900px;
$screen-750: 750px;
$screen-sm: 600px;
$screen-xs: 400px;

$screen-lg-max: $screen-xl - .1px;
$screen-md-max: $screen-lg - .1px;
$screen-sm-max: $screen-md - .1px;
$screen-xs-max: $screen-sm - .1px;

// Screen sizes (DO NOT CHANGE)
$screen-320px: 320px;
$screen-360px: 375px;
$screen-400px: 400px;
$screen-480px: 480px;
$screen-480px-1: ($screen-480px - 1);
$screen-520px: 520px;
$screen-520px-1: ($screen-520px - 1);
$screen-600px: 600px;
$screen-600px-1: ($screen-600px - 1);
$screen-760px: 760px;
$screen-760px-1: ($screen-760px - 1);
$screen-900px: 900px;
$screen-900px-1: ($screen-900px - 1);
$screen-1040px: 1040px;
$screen-1040px-1: ($screen-1040px - 1);
$screen-1200px: 1200px;
$screen-1200px-1: ($screen-1200px - 1);
$screen-1400px: 1400px;
$screen-1400px-1: ($screen-1400px - 1);

$container-max-width: 1600px;
$container-padding: 16px;
$container-padding-screen-sm: 32px;
$container-padding-screen-lg: 48px;

$color-grey-100: #DCE2EB;
$color-grey-200: #CBD1DB;
$color-grey-300: #B0B2B8;
$color-grey-400: #8C919D;
$color-grey-500: #5A5E6A;
$color-grey-600: #3E4149;
$color-red-primary: #D80212;
$color-green-primary: #4cb806;
$color-grey-header-bg: #636775;

$color-text-light: $color-grey-400;
$color-text-dark: $color-grey-500;
$color-text: $color-grey-500;
$color-borders: $color-grey-200;

$color-borders-semi-transparent-darken: transparentize(black, .85);
$color-borders-semi-transparent-lighten: transparentize(white, .85);

$color-link: $color-red-primary;
$color-link-hover: darken($color-red-primary, 10%);
$color-link-muted: $color-text-dark;
$color-link-muted-hover: darken($color-text-dark, 10%);

$color-tap-highlight: fade($color-text, 10%);

$opacity-text-light: .65;
$opacity-arrow-light: .25;

$box-shadow-light: 0 0 4px 0 rgba(0, 0, 0, 0.05);
$box-shadow-dark: 0 0 4px 0 rgba(0, 0, 0, 0.125);
$box-shadow-border: 0 0 0 1px $color-borders;
$box-shadow-brand: 0 0 4px 0 #B70511;

$border-radius-primary: 3px;
$border-weight-thick: 5px;

$svgicon-size-default: 24px;
$svgicon-size-sm: 16px;

$easing-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$transition-duration-default: .15s;

$menu-item-height-xl: 80px;
$menu-item-height-lg: 64px;
$menu-item-height-md: 48px;
$menu-item-height-sm: 40px;
$menu-item-height-xs: 32px;

$layout-header-logo-bar-height: 80px;
$layout-header-nav-bar-height: 56px;
$layout-header-dropdowns-height: 1px;
$layout-header-dropdowns-container-max-width: $screen-sm;

$z-index-header-layout-sticky: 25;
$z-index-header-layout-background: 25;
$z-index-body-backdrop: 5;

$input-font-size: 16px;
$formControl-bg-color: mix($color-grey-100, white);
$formControl-bg-gradient: linear-gradient(to bottom, $formControl-bg-color, white);
$formControl-border-color: $color-grey-200;
$formControl-box-shadow: inset 0 0 0 1px $formControl-border-color;
$formControl-box-shadow-hover: inset 0 0 0 1px $color-grey-300, 0 0 .5rem rgba($color-grey-300, .6);
$formControl-box-shadow-focus: inset 0 0 0 1px $color-red-primary, 0 0 .5rem rgba($color-red-primary, .6);
$formControl-placholder-color: $color-grey-300;
$formControl-text-color: rgb(90, 94, 106);
$input-border-color: #cbd1db;

$icon-image-size-sm: 48px;
$icon-image-size-md: 64px;
$icon-image-size-lg: 80px;

// Default Colors
$color-green: #59C736;
$color-darkgreen: darkgreen;
$color-blue: #005bff;
$color-red: #e30613;
$color-orange: #E89535;
$color-teal: #009492;
$color-eu-blue: #003399;
$color-eu-yellow: #FFCC00;

$icon-space-width: 40px;
$block-shadow-default: 0 0 8px rgba(0, 0, 0, .15);

// Line Heights (DO NOT CHANGE)
$line-height: 1.5;
$line-height-135: 1.35;
$line-height-120: 1.2;
$line-height-110: 1.1;


// Font Sizes (DO NOT CHANGE)
$font-size-075rem: 0.75rem;
$font-size-088rem: 0.88rem;
$font-size-100rem: 1rem;
$font-size-105rem: 1.05rem;
$font-size-110rem: 1.1rem;
$font-size-120rem: 1.2rem;
$font-size-h1: 2rem;
$font-size-h2: 1.5rem;
$font-size-h3: 1.35rem;
$font-size-h4: 1.1rem;
$font-size-h5: 1rem;
$font-size-ios-input: 16px;