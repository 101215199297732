@import "./mlpartsVariables";
@import "./mlpartsMixins";

.mlpartsFooter {
    @include mlpartsContainer();
}

$storeInBrno-max-height: 264px;

.mlpartsFooter__storeInBrno {
    position: relative;
    user-select: none;
    @media (max-width: $screen-xs-max) {
        padding-bottom: 1rem;
        border-bottom: 1px solid $color-grey-100;
    }
    @media (min-width: $screen-sm) {
        margin-top: 48px;
        //padding: 1px;
        @include mlpartsPaneBorderShadow;
    }
    @media (min-width: $screen-md) {
        display: flex;
        align-items: stretch;
    }
}

.mlpartsFooter__storeInBrno__link {
    display: flex;
    padding: 16px 0;
    position: relative;
    color: $color-text-dark;
    text-decoration: none !important;
    box-sizing: border-box;
    @media (min-width: $screen-sm) {
        height: $storeInBrno-max-height;
    }
    @media (min-width: $screen-md) {
        width: 50%;
        padding: 24px 0 0;
    }
}

a.mlpartsFooter__storeInBrno__link {
    @include mlpartsMenuItemArrowRight();
}

@include mlpartsPaneHover("a.mlpartsFooter__storeInBrno__link");

.mlpartsFooter__storeInBrno__text {
    display: block;
    user-select: text;

    span {
        display: block;
        opacity: $opacity-text-light;
    }
}

.mlpartsFooter__storeInBrno__title {
    @include mlpartsFontSizeXlResponsive;
    display: block;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-narrow;
}

.mlpartsFooter__storeInBrno__address {
    margin: 0;
}

.mlpartsFooter__storeInBrno__addressTitle {
    margin-top: 25px;
    display: block;
    font-size: 1.125rem;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-narrow;
}

.mlpartsFooter__storeInBrno__icon {
    width: 25%;
    height: 56px;
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 64px;
    background-image: url("../../mlparts.cz/store.png");
}

#mlpartsFooter__storeInBrno__navigateButton {
    height: 44px;
    line-height: 44px;
    border-radius: $border-radius-primary;
    padding-left: 25%;
    background-color: $color-grey-100;
    font-weight: $font-weight-semibold;
    color: $color-text-dark;
    box-shadow: $box-shadow-light;
    background-image: url("../../mlparts.cz/navigation.svg");
    background-position: calc(12.5% - 12px) center;
    background-size: 24px auto;
    background-repeat: no-repeat;
    display: block;
    @media (min-width: $screen-sm) {
        background-position: 24px center;
        text-align: center;
        padding-left: 40px;
        position: absolute;
        top: $storeInBrno-max-height - 24px - 44px;
        left: 25%;
        width: 50%;
    }
    @media (min-width: $screen-md) {
        left: 12.5%;
        min-width: 240px;
        width: 25%;
    }
    @media (min-width: $screen-md) {
        width: 25%;
    }
}

@include mlpartsPaneHover("#mlpartsFooter__storeInBrno__navigateButton");

.mlpartsFooter__storeInBrno__photo {
    @media (max-width: $screen-xs-max) {
        margin-top: 16px;
    }
    @media (min-width: $screen-md) {
        width: 50%;
    }
}

.mlpartsFooter__storeInBrno__photoImage {
    display: block;
    width: 100%;
    border-radius: $border-radius-primary;
    object-fit: cover;
    -webkit-user-drag: none;
    height: 100%;
    max-height: $storeInBrno-max-height;
}


.mlpartsFooter__benefitsNav {
    user-select: none;
    @media (min-width: $screen-sm) {
        margin-top: 48px;
        @include mlpartsPaneBorderShadow;
        background-image: mlpartsGridBackgroundLines(0, 2);
    }
    @media (min-width: $screen-md) {
        display: flex;
        background-image: mlpartsGridBackgroundLines(2, 0);
    }
}

.mlpartsFooter__benefitsNav__link {
    display: flex;
    height: $menu-item-height-xl;
    align-items: center;
    color: $color-grey-500;
    position: relative;
    text-decoration: none !important;
    @media (max-width: $screen-xs-max) {
        border-bottom: 1px solid $color-grey-100;
    }
    @media (min-width: $screen-sm) {
        flex-basis: 50%;
    }
    @include mlpartsMenuItemArrowRight;
}

@include mlpartsPaneHover(".mlpartsFooter__benefitsNav__link");

.mlpartsFooter__benefitsNav__link__icon {
    width: 64px;
    height: 64px;
    margin: auto;
    flex-grow: 1;
    @include mlpartsBackgroundImageContain;

    &.--deliveryForWorkshops {
        background-image: url('../../mlparts.cz/delivery_truck.png');
    }

    &.--pickupShopsTruck {
        background-image: url('../../mlparts.cz/delivery_truck.png');
    }

    &.--pickupShopsHand {
        background-image: url('../../mlparts.cz/box_in_hand.png');
    }

    &.--productReturn {
        background-image: url('../../mlparts.cz/box_in_hand.png');
    }
}

.mlpartsFooter__benefitsNav__link__text {
    padding-right: 32px;
    width: 75%;
    letter-spacing: $letter-spacing-narrow;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    @media (min-width: $screen-xl) {
        flex-direction: row;
        align-items: baseline;
    }
}

.mlpartsFooter__benefitsNav__link__title {
    @include mlpartsFontSizeXlResponsive;
    font-weight: $font-weight-bold;
    margin-right: .5em;
}

.mlpartsFooter__benefitsNav__link__subtitle {
    font-weight: $font-weight-regular;
    opacity: $opacity-text-light;
}

.mlpartsFooter__articleLinks {
    display: flex;
    height: $menu-item-height-xl;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
    border-top: 1px solid $color-grey-100;
    user-select: none;
    @media (max-width: $screen-md-max) {
        display: none;
    }
}

.mlpartsFooter__articleLinks__link {
    font-weight: $font-weight-semibold;
    letter-spacing: $letter-spacing-narrow;
    color: $color-grey-600;
    text-transform: uppercase;
    text-underline: none;

    @media (hover) {
        &:hover, &:active {
            color: $color-red-primary;
            text-decoration: none;
        }
    }
}


.mlpartsFooter__partners {
    display: flex;
    flex-wrap: wrap;
    user-select: none;
    border-top: 1px solid $color-grey-100;
    border-bottom: 1px solid $color-grey-100;
    @media (max-width: $screen-md-max) {
        display: none;
    }
}

.mlpartsFooter__partners__logos {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
}

.mlpartsFooter__partners__heureka {
    @include mlpartsBackgroundImageContain;
    background-image: url('../../mlparts.cz/heureka-gold.svg');
    height: 56px;
    flex-basis: 25%;
    min-width: 96px;
    text-decoration: none !important;
}

.mlpartsFooter__partners__customerRating {
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url('../../mlparts.cz/customer-rating.svg');
    height: 40px;
    font-size: .875rem;
    font-weight: $font-weight-regular;
    letter-spacing: $letter-spacing-narrow;
    text-align: center;
    color: $color-green-primary;
    display: flex;
    flex-basis: 25%;
    flex-direction: column-reverse;
    white-space: nowrap;
    text-decoration: none !important;

    @media (hover) {
        &:hover, &:active {
            color: $color-green-primary;
        }
    }
}

.mlpartsFooter__partners__deliveryLogos {
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 16px;
}

.mlpartsFooter__partners__deliveryLogo {
    @include mlpartsBackgroundImageContain;
    margin: 0 8px;

    &.--dpd {
        background-image: url('../../mlparts.cz/dpd.png');
        width: 54px;
        height: 24px;
    }

    &.--ulozenka {
        background-image: url('../../mlparts.cz/ulozenka.png');
        width: 61px;
        height: 24px;
    }

    &.--zasilkovna {
        background-image: url('../../mlparts.cz/zasilkovna.png');
        width: 80px;
        height: 16px;
    }

    &.--zasielkovna {
        background-image: url('../../mlparts.cz/zasielkovna.png');
        width: 80px;
        height: 16px;
    }

    &.--ppl {
        background-image: url('../../mlparts.cz/ppl.png');
        width: 73px;
        height: 12px;
    }
}

.mlpartsFooter__partners__comgate {
    flex-basis: 50%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mlpartsFooter__partners__comgateLogos {
    background-image: url('../../mlparts.cz/comgate-footer-logos.png');
    height: 44px;
    width: 90%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.mlpartsFooter__partners__tecdoc {
    height: 80px;
    margin: 0 auto;
    flex-basis: 50%;
    display: flex;
    align-items: center;
}

.mlpartsFooter__partners__tecdocLogo {
    flex-basis: 25%;
    display: flex;
    justify-content: center;

    img {
        max-width: 80px;
        width: auto;
        object-fit: contain;
        -webkit-user-drag: none;
    }
}

.mlpartsFooter__partners__tecdocText {
    flex-basis: 75%;
    font-size: 10px;
    font-weight: $font-weight-regular;
    letter-spacing: $letter-spacing-narrow;
    color: $color-grey-400;
    cursor: default;
}

.mlpartsFooter__kvikymartFooter {
    height: 48px;
    margin-bottom: 48px;
    user-select: none;
    display: grid;
    align-items: center;
    justify-content: center;
    color: $color-grey-400;
    font-size: .75rem;

    a {
        color: inherit;
        text-underline: none;

        strong {
            font-weight: $font-weight-bold;
        }

        @media (hover) {
            &:hover, &:active {
                color: $color-grey-500;
                text-decoration: none;
            }
        }
    }
}