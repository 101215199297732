@import "./mlpartsVariables";
@import "./mlpartsMixins";

.mlpartsHelpContact {
    user-select: none;
    letter-spacing: $letter-spacing-narrow;
    @media (min-width: $screen-sm) {
        color: white;
        display: flex;
        box-shadow: $box-shadow-dark;
        background-color: $color-grey-400;
        border-radius: $border-radius-primary;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 3rem;
    }

    .--dark & {
        box-shadow: none;
    }
}

.mlpartsHelpContact__question {
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    cursor: default;
    flex-grow: 1;
    width: 100%;
    height: $menu-item-height-xl;
    font-size: $font-size-lg;
    @media (min-width: $screen-sm) {
        font-size: $font-size-xl;
        height: 3rem;
        padding: 0 2rem;
        border-bottom: 1px solid $color-grey-300;
        justify-content: center;
    }
    @media (min-width: $screen-lg) {
        justify-content: flex-start;
        border: none;
        width: 50%;
        height: $menu-item-height-xl;
    }
}

.mlpartsHelpContact__buttons {
    flex-grow: 1;
    @media (min-width: $screen-lg) {
        width: 50%;
    }
}

.mlpartsHelpContact__buttons__inside {
    @media (min-width: $screen-sm) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.mlpartsHelpContact__buttons__email,
.mlpartsHelpContact__buttons__phone {
    height: $menu-item-height-xl;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 256px;
    font-weight: $font-weight-bold;;
    @media (min-width: $screen-sm) {
        flex-basis: 50%;
        flex-grow: 1;
    }
}

@include mlpartsPaneHover(".mlpartsHelpContact__buttons__email, .mlpartsHelpContact__buttons__phone", null, $color-grey-500);
@include mlpartsPaneHover(".--dark .mlpartsHelpContact__buttons__email, .--dark .mlpartsHelpContact__buttons__phone");

.mlpartsHelpContact__buttons__email__icon,
.mlpartsHelpContact__buttons__phone__icon {
    display: block;
    @include mlpartsBackgroundImageContain;
    background-size: 3rem auto;
    width: 25%;
    height: 100%;
    @media (min-width: $screen-lg) {
        width: 33.33%;
    }
}

.mlpartsHelpContact__buttons__email__icon {
    background-image: url("../../mlparts.cz/envelope.svg");
}

.mlpartsHelpContact__buttons__email__text {
    display: block;
    flex-grow: 1;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-narrow;
    font-size: 1.2rem;
    @media (min-width: $screen-lg) {
        text-align: center;
        margin-right: 1.5rem;
    }
}

.mlpartsHelpContact__buttons__phone__icon {
    background-image: url("../../mlparts.cz/phone@3x.png");
}

.mlpartsHelpContact__buttons__phone__text {
    flex-grow: 1;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    line-height: 1.2;
    font-size: 1.2rem;
}

.mlpartsHelpContact__buttons__phone__question {
    display: none;
}

.mlpartsHelpContact__buttons__phone__hours {
    @include mlpartsFontSizeResponsive(.9rem, 1rem);
    opacity: $opacity-text-light;
    white-space: nowrap;
    font-weight: $font-weight-regular;
}